<template>
  <div>
    <v-app>
      <b-form @submit.stop.prevent="formOnsubmit">
        <!-- Input Modal Trigger -->
        <b-form-group
          id="input-group-trigger-modal-patient"
          label="Pasien:"
          label-for="input-trigger-modal-patient"
        >
          <b-form-input
            id="input-trigger-modal-patient"
            v-model="display.patient_name"
            placeholder="Pilih Pasien"
            readonly
            @click="$bvModal.show('modal-patient')"
          >
          </b-form-input>
          <small class="text-danger">{{ error.patient_id }}</small>
        </b-form-group>

        <!-- Input Date -->
        <b-form-group
          id="input-group-date"
          label="Tanggal:"
          label-for="input-date"
        >
          <v-dialog
            ref="dialog"
            v-model="modal2"
            :return-value.sync="form.date"
            persistent
            width="350px"
          >
            <template v-slot:activator="{ on, attrs }">
              <b-form-input
                readonly
                v-bind="attrs"
                v-on="on"
                id="input-trigger-modal-patient"
                v-model="form.date"
              >
              </b-form-input>
            </template>

            <v-date-picker
              locale="id"
              v-if="modal2"
              v-model="form.date"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="modal2 = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(form.date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <small class="text-danger">{{ error.date }}</small>
        </b-form-group>

        <!-- Input Doctor -->
        <b-form-group
          id="input-group-doctor"
          label="Dokter:"
          label-for="input-doctor"
        >
          <treeselect
            v-model="form.doctor_id"
            :multiple="false"
            placeholder="Pilih Dokter"
            :options="doctors"
          />
          <small class="text-danger">{{ error.doctor_id }}</small>
        </b-form-group>

        <!-- Input History -->
        <b-form-group id="input-group-history">
          <label for="input-history">Riwayat: <em class="text-muted">opsional</em></label>
          <b-form-textarea
            id="input-history"
            v-model="form.history"
            rows="3"
            placeholder="Riwayat"
          ></b-form-textarea>
          <small class="text-danger">{{ error.history }}</small>
        </b-form-group>

        <!-- Input Notes -->
        <b-form-group id="input-group-advice">
          <label for="input-advice">Saran: <em class="text-muted">opsional</em></label>
          <b-form-textarea
            id="input-notes"
            v-model="form.notes"
            rows="3"
            placeholder="Catatan"
          ></b-form-textarea>
          <small class="text-danger">{{ error.notes }}</small>
        </b-form-group>

        <!-- Input Advice -->
        <b-form-group id="input-group-notes">
          <label for="input-notes">Catatan: <em class="text-muted">opsional</em></label>
          <b-form-textarea
            id="input-advice"
            v-model="form.advice"
            rows="3"
            placeholder="Saran"
          ></b-form-textarea>
          <small class="text-danger">{{ error.advice }}</small>
        </b-form-group>

        <!-- Input Medicine -->
        <!-- <b-form-group
          id="input-group-medicine"
          label="Obat:"
          label-for="input-medicine"
        >
          <treeselect
            v-model="medicine_id"
            :multiple="true"
            :options="medicines"
            @select="setPrescription"
            @deselect="removeMedicine"
          />
          <small class="text-danger">{{ error.medicine }}</small>
        </b-form-group> -->

        <!-- Input Modal Trigger -->
        <b-form-group id="input-group-trigger-modal-medicine">
          <label for="input-trigger-modal-medicine">Obat:</label>
          <b-input-group>
            <b-form-input
              id="input-trigger-modal-medicine"
              v-model="display.medicine_name"
              placeholder="Pilih Obat"
              readonly
              @click="$bvModal.show('modal-medicine')"
              style="border-right: none"
            >
            </b-form-input>
            <template #append>
              <b-input-group-text
                squared
                :class="btnClearMedicineClass"
                style="border-left: none"
                @click="resetMedicine"
                @mouseover="btnClearMedicineClass = 'bg-light-danger'"
                @mouseout="btnClearMedicineClass = 'bg-white'"
              >
                <i class="fas fa-sm fa-times text-danger"></i>
              </b-input-group-text>
            </template>
          </b-input-group>
          <small class="text-danger">{{ error.medicines }}</small>
        </b-form-group>

        <!-- <b-table hover :items="medicine_collection" :fields="fields">
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
          />
        </template>
      </b-table> -->

        <div
          class="bg-light"
          v-if="medicine_collection.length > 0"
        >
          <table
            class="table"
            v-if="medicine_collection.length > 0"
          >
            <tr>
              <th>Nama</th>
              <th>Dosis</th>
              <th>Frekuensi</th>
              <th>Hari</th>
              <th>Instruksi</th>
            </tr>
            <tr
              v-for="mdc in medicine_collection"
              :key="mdc.id"
            >
              <td>{{ mdc.name }}</td>
              <td>{{ mdc.dosage }}</td>
              <td>{{ mdc.frequency }}</td>
              <td>{{ mdc.days }}</td>
              <td>{{ mdc.instruction }}</td>
            </tr>
          </table>
        </div>

        <b-modal
          ref="my-modal"
          hide-footer
          title="Resep Obat"
          @hide="hideModalCancel"
        >
          <b-form @submit.stop.prevent="medicineFormOnsubmit">
            <!-- <div class="d-block text-center"> -->
            <b-form-group
              id="input-group-name"
              label="Nama:"
              label-for="input-name"
            >
              <b-form-input
                id="input-name"
                v-model="medicine_collection_form.name"
                placeholder="Nama obat"
                readonly
              ></b-form-input>
            </b-form-group>

            <!-- <b-form-group id="input-group-id" label="Id:" label-for="input-id">
              <b-form-input
                id="input-id"
                v-model="medicine_collection_form.id"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group> -->

            <b-form-group
              id="input-group-dosage"
              label="Dosis:"
              label-for="input-dosage"
            >
              <b-form-input
                id="input-dosage"
                v-model="medicine_collection_form.dosage"
                placeholder="100 mg"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-frequency"
              label="Frekuensi:"
              label-for="input-frequency"
            >
              <b-form-input
                id="input-frequency"
                v-model="medicine_collection_form.frequency"
                placeholder="1+0+1"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-days"
              label="Hari:"
              label-for="input-days"
            >
              <b-form-input
                id="input-days"
                v-model="medicine_collection_form.days"
                placeholder="7 hari"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-instruction"
              label="Instruksi:"
              label-for="input-instruction"
            >
              <b-form-input
                id="input-instruction"
                v-model="medicine_collection_form.instruction"
                placeholder="Setelah makan"
                required
              ></b-form-input>
            </b-form-group>
            <!-- </div> -->
            <!-- Submit & Cancel button -->
            <b-button
              type="submit"
              variant="primary"
            >Simpan</b-button>
            <b-button
              type="button"
              class="ml-2"
              variant="default"
              @click="hideModalCancel"
            >
              Cancel
            </b-button>
          </b-form>
        </b-modal>

        <!-- <div v-html="form.medicine"></div>
      <div v-text="form.medicine"></div> -->

        <!-- Button -->
        <b-button
          type="submit"
          variant="primary"
        >Simpan</b-button>
        <b-button
          type="button"
          class="ml-2"
          variant="default"
          @click="$router.push('/prescriptions/list')"
        >
          Batal
        </b-button>
      </b-form>

      <ModalPatient
        @chosenPatient="setPatient"
        @submitedPatient="setPatient"
      />
      <ModalMedicine
        @chosenMedicine="setPrescription"
        :selectedItem="medicine_collection"
        :checkbox="true"
      />
    </v-app>
  </div>
</template>

<script>

import Card from "@/view/content/Card.vue";
import ModalPatient from "@/component/general/ModalPatient.vue";
import ModalMedicine from "@/component/general/ModalMedicine.vue";
import Medicine from "@/component/prescriptions/Medicine.vue";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ApiService from "@/core/services/api.service.js";
import { getUser } from "@/core/services/jwt.service.js";

export default {

  name: "Add",

  props: {
    form: Object,
    route: String,
  },

  components: {
    Card,
    Medicine,
    ModalPatient,
    ModalMedicine,
  },

  data() {
    return {
      // Display Data
      display: {
        patient_name: "",
      },
      modal2: false,
      doctors: [],
      patients: [],
      medicines: [],
      medicine_id: [],
      medicineId: [],
      medicine_tes: `<table style="border-collapse: collapse; width: 100%;" border="1"> <tbody> <tr> <td style="width: 50%;">obat</td> <td style="width: 50%;">dosis</td> </tr> <tr> <td style="width: 50%;">sanmol</td> <td style="width: 50%;">100</td> </tr>`,
      // Error
      error: {
        date: null,
        patient_id: null,
        patient_name: "",
        doctor_id: null,
        doctor_name: "",
        history: "",
        notes: "",
        advice: "",
        medicine: "",
      },
      medicine: "",
      name_medicine: "",
      dosage: [],
      frequency: [],
      days: [],
      intructions: [],
      medicine_collection: [],
      medicine_collection_str: [],
      medicine_collection_form: {
        id: "",
        name: "",
        dosage: "",
        frequency: "",
        days: "",
        instruction: "",
      },
      medicine_collection_submit: false,
      medicine_active: [],
      // other
      doctorDisabled: true,
      // current user
      currentUser: getUser(),
      btnClearMedicineClass: "bg-white",
    };
  },

  methods: {
    setPatient(value) {
      this.form.patient_id = value.id;
      this.display.patient_name = value.name;
      if (this.currentUser.user_type_id != 1) {
        this.form.doctor_id = "";
        this.getDoctorOption();
      }
      this.doctorDisabled = false;
      this.$bvModal.hide("modal-patient");
    },

    onReady() {
      // Insert the toolbar before the editable area.
      //   editor.ui
      //     .getEditableElement()
      //     .parentElement.insertBefore(
      //       editor.ui.view.toolbar.element,
      //       editor.ui.getEditableElement()
      //     );
    },

    getMedicineActive() {
      ApiService.get("prescriptions/" + this.$route.params.id).then(
        (response) => {
          console.log("prescription", response);
          this.medicine_active = JSON.parse(response.data.data.medicine);
          this.medicine_collection = JSON.parse(response.data.data.medicine);
          console.log("medicine_collect", this.medicine_collection);
          console.log("med_actv", this.medicine_active);
          this.display.patient_name = response.data.data.patient_name;

          this.displayMedicineName(JSON.parse(response.data.data.medicine));

          let a;
          for (a = 0; this.medicine_active.length; a++) {
            this.medicine_id.push(this.medicine_active[a].id);
            console.log("med_actv", this.medicine_active);
            console.log("med_actv[a]", this.medicine_active[a].id);
            console.log("loop");
            console.log("medicine_id", this.medicine_id);
          }
        }
      );
    },

    hideModal() {
      this.$refs["my-modal"].hide();
    },

    async hideModalCancel() {
      if (this.medicine_collection_submit == false) {
        console.log("tidak tekan submit");
        // this.medicine_id.pop();
      }
      await this.$refs["my-modal"].hide();
      this.setMedicineSubmit();
    },

    setMedicineSubmit() {
      this.medicine_collection_submit = false;
    },

    async setPrescription(value) {
      if (this.medicine_collection.length > 0) {
        let a;
        for (a = 0; a < this.medicine_collection.length; a++) {
          if (this.medicine_collection[a].id == value.id) {
            this.medicine_collection.splice(a, 1);
            if (this.medicine_collection.length == 0) {
              this.display.medicine_name = "";
              break;
            } else if (this.medicine_collection.length > 0) {
              this.displayMedicineName(this.medicine_collection);
              break;
            }
            break;
          } else if (a == this.medicine_collection.length - 1) {
            this.$bvModal.hide("modal-medicine");
            console.log("value", value);
            await this.medicine_id;
            this.medicine_id = value.id;
            console.log("medicine_id", this.medicine_id);
            // this.medicine_collection_form.id = "";
            // this.medicine_collection_form.name = "";
            this.medicine_collection_form.dosage = "";
            this.medicine_collection_form.frequency = "";
            this.medicine_collection_form.days = "";
            this.medicine_collection_form.instruction = "";
            this.setMedicine(value.id);
          }
        }
      } else {
        this.$bvModal.hide("modal-medicine");
        console.log("value", value);
        await this.medicine_id;
        this.medicine_id = value.id;
        console.log("medicine_id", this.medicine_id);
        // this.medicine_collection_form.id = "";
        // this.medicine_collection_form.name = "";
        this.medicine_collection_form.dosage = "";
        this.medicine_collection_form.frequency = "";
        this.medicine_collection_form.days = "";
        this.medicine_collection_form.instruction = "";
        this.setMedicine(value.id);
      }
    },

    setMedicine(id) {
      // console.log("alhamdulillah sampai sini");
      ApiService.get("medicines/" + id).then((response) => {
        console.log("medicines", response.data.data);
        this.medicine = response.data.data;
        this.name_medicine = response.data.data.name;
        this.medicine_collection_form.name = response.data.data.name;
        this.medicine_collection_form.id = response.data.data.id;
        this.$refs["my-modal"].show();
      });
    },

    displayMedicineName(medicine) {
      if (medicine.length == 0) {
        this.display.medicine_name = "";
      } else {
        let str = "";
        medicine.forEach(function (value, index) {
          str += value.name;
          if (index != medicine.length - 1) {
            if (medicine.length > 1) str += ", ";
          }
        });
        this.display.medicine_name = str;
      }
    },

    resetMedicine() {
      this.medicine_collection.splice(0, this.medicine_collection.length);
      console.log("reset", this.medicine_collection);
      this.display.medicine_name = "";
    },

    removeMedicine(event) {
      console.log(event);
      console.log(event.id);
      // var arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

      // for (var i = 0; i < arr.length; i++) {
      //   if (arr[i] === 5) {
      //     arr.splice(i, 1);
      //   }
      // }
      let a;
      for (a = 0; a < this.medicine_collection.length; a++) {
        if (event.id == this.medicine_collection[a].id) {
          console.log("hapus");
          this.medicine_collection.splice(a, 1);
        }
      }
    },

    async list() {
      this.items = await module.list("medicines");
      this.dataLoaded = true;
    },

    async getDoctorOption() {
      let route
      if (typeof this.form.patient_id !== 'undefined' && this.form.patient_id != '') {
        console.info('ruined')
        route = `doctors/get-by-patient/${this.form.patient_id}`
      } else {
        route = 'doctors'
      }
      let response = await module.setDoctorTreeSelect(route);
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.doctors = response.data;
        this.doctors.unshift({ label: "Pilih Dokter", id: "", isDisabled: true, })
      }
    },

    async getPatientOption() {
      let response = await module.setTreeSelect("patients");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.patients = response.data;
      }
    },

    async getMedicineOption() {
      let response = await module.setTreeSelect("medicines");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.medicines = response.data;
      }
    },

    medicineFormOnsubmit() {
      this.medicine_collection_submit = true;
      // console.log("yang akan di input", this.medicine_collection_form);
      // this.medicine_collection.push(this.medicine_collection_form);
      let clone = { ...this.medicine_collection_form };
      this.medicine_collection.push(clone);

      // console.log("hasil", this.medicine_collection);
      this.displayMedicineName(this.medicine_collection);
      this.hideModal();
    },

    async formOnsubmit() {
      this.medicine_collection_str = await JSON.stringify(
        this.medicine_collection
      );
      this.form.medicine = await this.medicine_collection_str;
      console.log("form.medicine", this.form.medicine);
      let response = await module.submit(this.form, this.route);

      if (response.state == "error") {
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/prescriptions/list");
      }
    },
  },

  mounted() {
    this.getDoctorOption()
    this.getPatientOption()
    this.getMedicineOption()
    this.getMedicineActive()
  },

  watch: {
    'form.patient_id': function (newVal) {
      this.getDoctorOption()
    },
    "form.patient_name": function (newVal, oldVal) {
      this.display.patient_name = newVal
    },
  },

}
</script>

